<template>
  <div class="pa-2 mb-4">
    <v-row v-if="fetchingApprovalData" class="mb-10">
      <v-col cols="12">
        <div class="d-flex flex-column align-center justify-center">
          <p class="text-subtitle-1 text-center mb-4">
            {{ $t("fetching_approval_data_message") }}
          </p>
          <v-progress-circular indeterminate size="35" color="blue darken-2" />
        </div>
      </v-col>
    </v-row>
    <div v-if="!fetchingApprovalData && !isAuthenticated">
      <v-alert type="error" outlined>
        {{ $t("not_authorized_error") }}
      </v-alert>
    </div>
    <div v-if="!fetchingApprovalData && isAuthenticated && !isSubmitted">
      <approval-content @submitted="handleSubmitted" />
    </div>
    <authenticate-jde
      v-if="!fetchingApprovalData"
      @authenticated="handleUserAuthenticated"
    />
    <div v-if="isSubmitted">
      <v-alert color="success" outlined v-if="responseAsArray.length">
        <p
          class="mb-2"
          :class="getTextColor"
          v-for="(row, index) in responseAsArray"
          :key="index"
        >
          {{ row.key }}: <span class="green--text">{{ row.value }}</span>
        </p>
      </v-alert>
      <div class="d-flex justify-center align-center">
        <v-tooltip bottom>
          <template #activator="{ on, attrs }">
            <v-icon
              x-large
              color="primary"
              v-on="on"
              @click="goBack"
              v-bind="attrs"
            >
              mdi-microsoft-xbox-controller-menu
            </v-icon>
          </template>
          <span>{{ $t("return_to_menu_tooltip") }}</span>
        </v-tooltip>
      </div>
    </div>
  </div>
</template>

<script>
import { DateTime } from "luxon";
import { mapState, mapActions, mapMutations } from "vuex";
import AuthenticateJDE from "@/components/AuthenticateJDE.vue";
import ApprovalContent from "@/components/Approval/ApprovalContent.vue";

export default {
  name: "Approval",
  components: {
    "authenticate-jde": AuthenticateJDE,
    ApprovalContent,
  },
  data: () => ({
    notes: "",
    isLoading: false,
    fetchingApprovalData: false,
    notificationId: 0,
    setToDone: false,
    notesError: false,
    payload: {},
    isAuthenticated: false,
    isSubmitted: false,
    responseAsArray: [],
  }),
  beforeRouteLeave(to, from, next) {
    localStorage.removeItem("requires-auth");
    localStorage.removeItem("JDEToken");
    if (this.setToDone) {
      next();
      return;
    }
    this.updateLinkedInformationStatus("PENDING");
    next();
  },
  computed: {
    ...mapState({
      linkedData: (state) => state.company.linkedData,
      currentUser: (state) => state.users.currentUser,
      selectedConnector: (state) => state.connectors.selectedConnector,
      outlookState: (state) => state.common.outlookState,
      staticValues: (state) => state.company.staticValues,
    }),
    getTextColor() {
      return this.$vuetify.theme.isDark ? "white--text" : "black--text";
    },
    needsAuthOnExecution() {
      if (this.linkedData.approval_auth_on_execution) return true;
      if (this.linkedData.rejection_auth_on_execution) return true;
      return false;
    },
    generateTimelineData() {
      return this.linkedData.approval_hierarchy.map((item) => ({
        ...item,
        pending: item.status === "PENDING",
        processedAt: item.processed_at
          ? DateTime.fromMillis(item.processed_at).toFormat("yyyy-MM-dd HH:mm")
          : false,
      }));
    },
    hasLineItems() {
      return this.linkedData.line_items?.length || false;
    },
    rowData() {
      if (!this.hasLineItems) return [];
      return this.linkedData.line_items;
    },
    tableData() {
      if (!this.hasLineItems) return {};
      return { data: { rowset: this.linkedData.line_items } };
    },
  },
  async created() {
    this.notificationId = this.$route.query.id;

    await this.fetchApprovalData();
    // TODO: This is a hack because we figured its good to change Approvals at the last moment

    if (this.hasConfigForLineItems) {
      this.setFileMeta({
        mo_key: "",
        associatedMORaw: this.linkedData.details_config.associatedMo,
        moKeyRaw: this.linkedData.details_config.mappedStructure,
      });
    }
    if (this.linkedData.approval_auth_on_execution) {
      this.isAuthenticated = false;
    } else {
      this.isAuthenticated = true;
    }
  },
  methods: {
    ...mapActions({
      updateNotification: "notifications/updateNotification",
      updateApprovalNotificationInStore:
        "notifications/updateApprovalNotification",
      showSnackbar: "common/showSnackbar",
    }),
    ...mapMutations({
      setFileMeta: "company/setFileMeta",
      updateCurrentFlow: "company/updateCurrentFlow",
      setLinkedData: "company/setLinkedData",
    }),
    handleSubmitted({ parsedResponse }) {
      this.responseAsArray = parsedResponse;
      this.isSubmitted = true;
    },
    goBack() {
      this.isSubmitted = false;
      this.setToDone = true;
      this.$router.push("/");
    },
    setFakeCurrentBotflow(mainConfig = {}) {
      // TODO: This is a hack because we figured its good to change Approvals at the last moment
      this.updateCurrentFlow({
        name: "Approval",
        config: {
          main_config: {
            ...mainConfig,
            auth_on_exec: this.needsAuthOnExecution,
            crud_actions: {},
          },
        },
      });
    },
    handleUserAuthenticated() {
      this.isAuthenticated = true;
    },
    async fetchApprovalData() {
      try {
        this.fetchingApprovalData = true;
        const approvalData = await this.$api.config.getLinkedData({
          company_id: this.currentUser.company_id,
          link_name: "APPROVAL",
          id: parseInt(this.notificationId),
        });
        if (!approvalData) return;
        this.setLinkedData(approvalData.linked_data);
        this.setFakeCurrentBotflow();
        await this.updateLinkedInformationStatus("IN PROGRESS");
      } catch (error) {
        this.$logError(error);
      } finally {
        this.fetchingApprovalData = false;
      }
    },
    async updateLinkedInformationStatus(status) {
      try {
        await this.updateNotification({
          status,
          id: parseInt(this.notificationId),
        });
      } catch (error) {
        this.$logError(error);
      }
    },
  },
};
</script>
