<template>
  <div class="pa-2">
    <v-sheet
      rounded
      outlined
      class="pa-2 mb-2"
      v-for="(lineItem, index) in getLineItems"
      :key="index"
    >
      <div class="d-flex justify-end mb-4" v-if="hasConfigForLineItems">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-bind="attrs"
              v-on="on"
              color="primary"
              @click="showMODialog(lineItem)"
            >
              mdi-archive-eye-outline
            </v-icon>
          </template>
          <span>{{ $t("view_files_label") }}</span>
        </v-tooltip>
      </div>
      <v-simple-table dense>
        <template v-slot:default>
          <tbody>
            <tr v-for="(key, index) in Object.keys(lineItem)" :key="index">
              <td class="text-capitalize">{{ $t(key) }}</td>
              <td>{{ lineItem[key] }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-sheet>
    <view-files ref="viewFiles" />
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";

import ViewFiles from "@/components/ViewFiles.vue";
export default {
  name: "ApprovalLines",
  components: { ViewFiles },
  computed: {
    ...mapState({
      linkedData: (state) => state.company.linkedData,
      fileMeta: (state) => state.company.fileMeta,
    }),
    getLineItems() {
      if (Object.keys(this.linkedData).includes("line_items")) {
        return this.linkedData.line_items || [];
      } else {
        return [];
      }
    },
    hasConfigForLineItems() {
      return (
        this.linkedData.details_config !== null &&
        Object.keys(this.linkedData?.details_config || {}).length > 0
      );
    },
  },
  methods: {
    ...mapMutations({
      setFileMeta: "company/setFileMeta",
      updateCurrentFlow: "company/updateCurrentFlow",
      setLinkedData: "company/setLinkedData",
    }),
    showMODialog(lineItem) {
      const moConfig = this.linkedData.details_config;
      const moKey = moConfig?.mappedStructure
        .map((row) => {
          if (row.type === "static") {
            return row.mappedValue;
          } else {
            return lineItem[row.mappedValue] || "";
          }
        })
        .join("|");
      const fileMeta = {
        mo_key: moKey,
        associatedMORaw: moConfig.associatedMo,
        moKeyRaw: moConfig.mappedStructure,
      };
      this.setFileMeta(fileMeta);
      // TODO: This is a hack because we figured its good to change Approvals at the last moment
      this.updateCurrentFlow({
        config: {
          main_config: {
            additional_data_type: moConfig.additionalDataType,
            crud_actions: {},
          },
        },
      });
      this.$refs.viewFiles.showDialog(moKey);
    },
  },
};
</script>
