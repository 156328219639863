<template>
  <v-sheet class="pa-2">
    <div class="d-flex align-center justify-center">
      <div class="timeline">
        <div
          class="timeline-item"
          v-for="(item, index) in generateTimelineData"
          :key="index"
        >
          <div class="timeline-item__indicator">
            <span :style="getTimelineColor(item)" />
          </div>
          <div class="timeline-item__details">
            <p class="font-weight-bold text-subtitle-2 mb-1">
              {{ item.email }}
            </p>
            <p class="text-body-2 mb-1" v-if="item.status">
              {{ $t(item.status) }}
            </p>
            <p class="text-body-2 mb-1" v-if="item.notes">
              {{ item.notes }}
            </p>
            <p class="text-body-2 mb-2 text--secondary" v-if="item.processedAt">
              {{ item.processedAt }}
            </p>
            <div class="flex mb-2">
              <v-chip outlined color="primary" small v-if="item.override">
                <v-icon left> mdi-debug-step-over </v-icon> Override
              </v-chip>
            </div>
          </div>
        </div>
      </div>
    </div>
  </v-sheet>
</template>

<script>
import { DateTime } from "luxon";
import { mapState } from "vuex";
export default {
  name: "ApprovalHierarchy",
  computed: {
    ...mapState({
      linkedData: (state) => state.company.linkedData,
    }),
    generateTimelineData() {
      return this.linkedData.approval_hierarchy
        .filter((item) => item.type !== "NOTIFICATION")
        .map((item) => ({
          ...item,
          pending: item.status === "PENDING",
          processedAt: item.processed_at
            ? DateTime.fromMillis(item.processed_at).toFormat(
                "yyyy-MM-dd HH:mm"
              )
            : false,
        }));
    },
  },
  methods: {
    getTimelineColor(item) {
      let color = "";
      if (
        item.ab_number === this.currentBotUser?.ab_number &&
        item.email === this.currentBotUser.email
      ) {
        color = "#2196f3";
      }
      switch (item.status) {
        case "APPROVED":
          color = "#4caf50";
        case "REJECTED":
          color = "error";
        case "SKIPPED":
        case "OVERRIDEN":
          color = "#fb8c00";
        case "PENDING":
          color = "#ffeb3b";
        default:
          color = "#2196f3";
      }
      return `background-color: ${color}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.timeline {
  width: 100%;
  &-item {
    display: flex;
    &__indicator {
      position: relative;
      display: flex;
      align-items: center;
      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: calc(50% - 1px);
        width: 2px;
        height: 100%;
        background-color: gray;
      }
      span {
        width: 25px;
        height: 25px;
        border-radius: 50%;
        margin: 0 10px;
        display: block;
        z-index: 1;
      }
    }
    &__details {
      p {
        word-break: break-all;
        white-space: pre-wrap;
      }
    }
  }
}
</style>