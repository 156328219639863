<template>
  <div>
    <v-sheet rounded elevation="2" class="mb-5 pa-1 brand-header">
      <div class="flex align-center">
        <v-icon @click="cancel" color="accent" class="mr-3">
          mdi-chevron-left
        </v-icon>
        <div class="flex-grow-1">
          <h6 class="text-h6 font-weight-bold">{{ $t("approval_title") }}</h6>
        </div>
      </div>
    </v-sheet>
    <v-row class="mb-6">
      <v-col cols="12" md="5">
        <v-row dense>
          <v-col cols="12" md="6" v-for="(val, key) in getData" :key="key">
            <v-text-field
              outlined
              readonly
              dense
              :label="$t(key)"
              :value="val"
              hide-details
            />
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" md="4">
        <div class="flex justify-center">
          <v-sheet outlined class="pa-4" width="600" rounded elevation="2">
            <div class="w_full flex-column align-center justify-center">
              <v-textarea
                v-model="notes"
                outlined
                :label="$t('notes_label')"
                :rows="4"
                class="w__full"
                dense
                :error="notesError"
                :error-messages="notesError ? ['This field is required'] : ''"
              />
              <div class="d-flex flex-wrap justify-space-around align-center">
                <v-btn
                  class="page-btn--primary ma-1"
                  @click="approve(true)"
                  :loading="isLoading"
                  small
                >
                  {{ $t("approve_label") }}
                </v-btn>
                <v-btn
                  class="page-btn--error ma-1"
                  @click="reject(false)"
                  :loading="isLoading"
                  small
                >
                  {{ $t("reject_label") }}
                </v-btn>
              </div>
            </div>
          </v-sheet>
        </div>
      </v-col>
    </v-row>
    <div class="d-flex justify-center">
      <v-btn small class="page-btn--error" @click="cancel">
        {{ $t("cancel_label") }}</v-btn
      >
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import fieldsAPI from "@/api/fields";

export default {
  name: "ApprovalInformation",
  data: () => ({
    setToDone: false,
    notificationId: 0,
    notes: "",
    notesError: false,
    payload: {},
    obotApprovalType: "",
    isLoading: false,
  }),
  computed: {
    ...mapState({
      linkedData: (state) => state.company.linkedData,
      companySettings: (state) => state.company.settings,
      currentFlow: (state) => state.company.currentFlow,
      currentUser: (state) => state.users.currentUser,
      selectedConnector: (state) => state.connectors.selectedConnector,
      outlookState: (state) => state.common.outlookState,
      staticValues: (state) => state.company.staticValues,
    }),
    getData() {
      const approvalData = JSON.parse(JSON.stringify(this.linkedData));
      const keysToRemove = [
        "approval_mapping",
        "rejection_mapping",
        "line_items",
        "approval_hierarchy",
        "rejection_orchestration",
        "approval_orchestration",
        "header_config",
        "details_config",
        "approval_id",
        "available_levels",
        "approval_route_details",
        "current_level",
        "approval_auth_on_execution",
        "rejection_auth_on_execution",
        "obot_approval_type",
      ];
      keysToRemove.forEach((key) => {
        delete approvalData[key];
      });
      return approvalData;
    },
    getApprovalData() {
      const approvalData = {};
      const approvalMapping = this.linkedData.approval_mapping;
      approvalMapping.forEach((item) => {
        if (item.key === "notes") return;
        if (item.keyType === "dynamic") {
          const value =
            this.getStaticFilterValue(item.key) || this.linkedData[item.key];
          approvalData[item.value] = value;
        } else {
          approvalData[item.value] = item.key;
        }
      });
      return approvalData;
    },

    getRejectionData() {
      const rejectionData = {};
      const rejectionMapping = this.linkedData.rejection_mapping;
      rejectionMapping.forEach((item) => {
        if (item.key === "notes") return;
        if (item.keyType === "dynamic") {
          const value =
            this.getStaticFilterValue(item.key) || this.linkedData[item.key];
          rejectionData[item.value] = value;
        } else {
          rejectionData[item.value] = item.key;
        }
      });
      return rejectionData;
    },
    hasConfigForLineItems() {
      return (
        this.linkedData.details_config !== null &&
        Object.keys(this.linkedData?.details_config || {}).length > 0
      );
    },
    hasConfigForHeaderItems() {
      return this.linkedData.header_config !== null;
    },
    shouldNotSubmit() {
      if (!this.linkedData) return false;
      const approvalHierarchy = this.linkedData.approval_hierarchy;
      const currentApprover = approvalHierarchy.find(
        (approver) =>
          approver.email === this.currentUser.email &&
          approver.ab_number === this.currentUser?.ab_number
      );
      return !currentApprover.approval_action;
    },
  },
  async mounted() {
    this.notificationId = this.$route.query.id;
    this.obotApprovalType = this.linkedData.obot_approval_type;
  },
  methods: {
    ...mapActions({
      updateNotification: "notifications/updateNotification",
      updateApprovalNotificationInStore:
        "notifications/updateApprovalNotification",
      showSnackbar: "common/showSnackbar",
    }),
    ...mapMutations({
      setFileMeta: "company/setFileMeta",
      updateCurrentFlow: "company/updateCurrentFlow",
      setLinkedData: "company/setLinkedData",
    }),
    async extractOutlookValue(value) {
      const mailboxItem = Office.context.mailbox.item;
      if (!mailboxItem || this.outlookState === "Compose") return;
      switch (value) {
        case "STATIC_FROM":
          return mailboxItem.from.emailAddress;
        case "STATIC_FROM_NAME":
          return mailboxItem.from.displayName;
        case "STATIC_TO":
          const currentUser = mailboxItem.to.find(
            (user) => user.emailAddress === this.currentUser.email
          );
          if (!currentUser) {
            return mailboxItem.to.first.emailAddress;
          }
          return currentUser.emailAddress;
        case "STATIC_CC":
          return mailboxItem.cc?.first.emailAddress;
        case "STATIC_SUBJECT":
          return mailboxItem.subject;
        default:
          return "";
      }
    },
    extractStaticValue(type) {
      switch (type) {
        case "STATIC_JDE_USER":
          return this.currentUser.jde_user;
        case "STATIC_JDE_COMPANY":
          return this.currentUser.jde_company;
        case "STATIC_JDE_AB":
          return this.currentUser?.ab_number;
        case "CURRENT_USER_EMAIL":
          return this.currentUser?.email;
        default:
          return undefined;
      }
    },
    getStaticFilterValue(value) {
      const staticValues = [];
      const outlookValues = [];
      this.staticValues.forEach((field) => {
        if (field.type === "standard") staticValues.push(field.value);
        if (field.type === "outlook") outlookValues.push(field.value);
      });
      if (staticValues.includes(value)) {
        return this.extractStaticValue(value);
      }
      if (outlookValues.includes(value)) {
        return this.extractOutlookValue(value);
      }
    },
    cancel() {
      this.updateLinkedInformationStatus("PENDING");
      this.setToDone = true;
      this.$router.push("/");
    },
    beautifyKey(key) {
      return key
        .replace(/_/gi, " ")
        .replace(/\w\S*/g, (w) => w.replace(/^\w/, (c) => c.toUpperCase()));
    },
    async updateLinkedInformationStatus(status) {
      try {
        await this.updateNotification({
          status,
          id: parseInt(this.notificationId),
        });
      } catch (error) {
        this.$logError(error);
      }
    },
    approve() {
      this.payload = {
        ...this.getApprovalData,
      };
      const notesField = this.linkedData.approval_mapping.find(
        (item) => item.key === "notes"
      );
      if (notesField) {
        this.payload[notesField.value] = this.notes;
      }
      this.submit(this.linkedData.approval_orchestration, true);
    },
    reject() {
      if (!this.notes) {
        this.notesError = true;
        return;
      }
      const notesField = this.linkedData.rejection_mapping.find(
        (item) => item.key === "notes"
      );
      this.payload = {
        ...this.getRejectionData,
        [notesField.value]: this.notes,
      };
      this.notesError = false;
      this.submit(this.linkedData.rejection_orchestration, false);
    },
    async submit(orchName, approve) {
      let token;
      if (this.linkedData.approval_auth_on_execution && approve) {
        token = localStorage.getItem("JDEToken");
      }
      if (this.linkedData.rejection_auth_on_execution && !approve) {
        token = localStorage.getItem("JDEToken");
      }
      const config = {
        org_id: this.companySettings.company_id,
        orchestration_name: orchName,
        botflow_name: "Approval",
        user_email: this.currentUser.email,
        response_format: "STANDARD",
        token,
      };

      const approvalData = {
        status: approve ? "APPROVED" : "REJECTED",
        notes: this.notes,
        ab_number: this.currentUser?.ab_number,
        approval_id: this.linkedData.approval_id,
      };
      try {
        this.isLoading = true;
        if (!this.shouldNotSubmit) {
          const response = await fieldsAPI.submit(config, this.payload);
          const parsedResponse = this.parseResponseAsString(
            response.orchestration_response
          );
          this.$emit("submitted", { parsedResponse });
        }
        this.setToDone = true;
        await this.updateApprovalNotificationInStore({
          id: parseInt(this.notificationId),
          approvalData,
          env: this.selectedConnector.environment,
          connectorId: this.selectedConnector.connector_id,
          obotApprovalType: this.obotApprovalType,
        });
        this.showSnackbar({
          type: "success",
          message: `Entry successfully ${approve ? "approved" : "rejected"}!`,
        });
        if (this.shouldNotSubmit) {
          this.$router.push("/");
        }
      } catch (error) {
        this.$logError(error);
        let message = error.message;
        if (error.response?.data?.orch_response_message) {
          message = error.response.data?.orch_response_message;
        }
        this.showSnackbar({ type: "error", message });
      }
      this.isLoading = false;
    },
    parseResponseAsString(response) {
      if (!response) return;
      const parsedResponse = [];
      Object.keys(response).forEach((key) => {
        parsedResponse.push({
          key: key,
          value: response[key],
        });
      });
      return parsedResponse;
    },
  },
};
</script>

<style>
</style>