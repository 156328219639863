<template>
  <div>
    <v-tabs-items v-model="currentTab">
      <v-tab-item value="information">
        <Information @submitted="handleSubmitted" />
      </v-tab-item>
      <v-tab-item value="hierarchy">
        <Hierarchy />
      </v-tab-item>
      <v-tab-item value="lines">
        <Lines />
      </v-tab-item>
    </v-tabs-items>
    <v-bottom-navigation
      v-model="currentTab"
      color="primary"
      fixed
      height="71px"
    >
      <v-btn value="information" @click="changeTab('information')">
        <span>Information</span>
        <v-icon>mdi-information-outline</v-icon>
      </v-btn>
      <v-btn value="hierarchy" @click="changeTab('hierarchy')">
        <span>Hierarchy</span>
        <v-icon>mdi-history</v-icon>
      </v-btn>
      <v-btn value="lines" @click="changeTab('lines')">
        <span>Related Items</span>
        <v-icon>mdi-archive-eye-outline</v-icon>
      </v-btn>
    </v-bottom-navigation>
    <view-files ref="viewFiles" />
  </div>
</template>

<script>
import Lines from "@/components/Approval/Lines.vue";
import Hierarchy from "@/components/Approval/Hierarchy.vue";
import Information from "@/components/Approval/Information.vue";
import ViewFiles from "@/components/ViewFiles.vue";

export default {
  name: "ApprovalContent",
  components: { Lines, Hierarchy, Information, ViewFiles },
  data: () => ({
    currentTab: "information",
    tabsDialog: false,
  }),
  methods: {
    changeTab(tab) {
      this.currentTab = tab;
    },
    handleSubmitted({ parsedResponse }) {
      this.$emit("submitted", { parsedResponse });
    },
    viewFiles() {
      const moConfig = this.linkedData.header_config;
      const moKey = moConfig?.mappedStructure
        .map((row) => {
          if (row.type === "static") {
            return row.mappedValue;
          } else {
            return this.linkedData[row.mappedValue];
          }
        })
        .join("|");
      const fileMeta = {
        mo_key: moKey,
        associatedMORaw: moConfig.associatedMo,
        moKeyRaw: moConfig.mappedStructure,
      };
      this.setFileMeta(fileMeta);
      this.setFakeCurrentBotflow({
        additional_data_type: moConfig.additionalDataType,
      });
      this.$refs.viewFiles.showDialog(moKey);
    },
  },
};
</script>
